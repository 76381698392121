<script>
import CardOneValue from "@/components/DashBoard/CardOneValue.vue";
import AxiosService from "@/axiosServices/AxiosService";
export default {
  name: "DashboardTesttry2",
  components: {
    CardOneValue,
  },
  created() {
    this.setFiltroDate();
    this.getData();
  },
  data() {
    return {
      data: null,
      loadingData: true,
      currentPageRegioni: 1,
      currentPageAgenti: 1,
      filter: "",
      perPage: 10,

      regioniFields: [
        // {
        //   key: "id",
        //   label: "ID",
        // },
        {
          key: "nome",
          label: "Regione",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "Visite",
          label: "Visite",
          sortable: true,
          thStyle: { width: "10%" },
        },
        // {
        //   key: "Farmacie",
        //   label: "Farmacie",
        //   sortable: true,
        //   thStyle: { width: "10%" },
        // },
        {
          key: "Vendite",
          label: "Ordini",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "ValoreVendite",
          label: "Valore Vendite",
          sortable: true,
          thStyle: { width: "10%" },
        },
      ],

      agentiFields: [
        {
          key: "nome",
          label: "Agente",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "Visite",
          label: "Visite",
          sortable: true,
          thStyle: { width: "10%" },
        },
        // {
        //   key: "Farmacie",
        //   label: "Farmacie",
        //   sortable: true,
        //   thStyle: { width: "10%" },
        // },
        {
          key: "Vendite",
          label: "Ordini",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "ValoreVendite",
          label: "Valore Vendite",
          sortable: true,
          thStyle: { width: "10%" },
        },
      ],
      datepickerValue: new Date(),
      datepickerValue2: "",
      loadingEsportaDatiCrm: false,
    };
  },
  methods: {
    handleEsportaDatiCrm() {
      this.loadingEsportaDatiCrm = true;
      const service = new AxiosService("Crm/ExportaDatiCrm");
      service
        .create({
          dallaData: this.datepickerValue,
          allaData: this.datepickerValue2,
        })
        .then((res) => {
          this.$successToast(
            "Dati CRM esportati con successo. Dettagli nei log"
          );
          console.log("DATI CRM ESPORTATI: ", res);
          window.open("https://api-yoube.whappy.it/" + res, "_blank").focus();
        })
        .catch((err) => {
          this.$errorToast(
            "Errore durante l'esportazione dei dati CRM. Più dettagli nei log"
          );
          console.log("ERRORE ESPORTAZIONE DATI CRM: ", err);
        })
        .finally(() => {
          this.loadingEsportaDatiCrm = false;
        });
    },
    getData() {
      this.loadingData = true;
      const service = new AxiosService("Ecommerce/GetBOStats");

      service
        .create({
          start: this.datepickerValue,
          end: this.datepickerValue2,
        })
        .then((res) => {
          this.data = res;
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    setFiltroDate() {
      this.datepickerValue = new Date();
      this.datepickerValue.setDate(1);
      this.datepickerValue2 = new Date();
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <h1>Dashboard yOUbe</h1>

    <b-row class="pb-5">
      <b-col cols="12">
        <div class="b-card">
          <h2>Filtri</h2>

          <b-row>
            <b-col>
              <b-form-datepicker
                id="datepicker"
                v-model="datepickerValue"
                class="mb-2"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
              <label
                for="datepicker"
                class="ml-2 text-left"
                style="min-width: 3rem"
                >From</label
              >
            </b-col>
            <b-col>
              <div class="d-flex flex-column align-items-start">
                <b-form-datepicker
                  id="datepicker2"
                  v-model="datepickerValue2"
                  class="mb-2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                ></b-form-datepicker
                ><label
                  for="datepicker2"
                  class="ml-2 text-left"
                  style="min-width: 3rem"
                >
                  To</label
                >
              </div>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="12" class="text-right">
              <div class="flex justify-content-end align-items-center">
                <b-button
                  variant="primary"
                  @click="getData()"
                  :disabled="loadingData"
                >
                  <b-spinner v-if="loadingData" small></b-spinner>
                  <span v-if="loadingData" class="ml-2"
                    >Caricamento in corso...</span
                  >
                  <span v-else>Filtra</span>
                </b-button>
                <b-button
                  @click="handleEsportaDatiCrm"
                  variant="primary"
                  class=" ml-2"
                  :disabled="loadingEsportaDatiCrm"
                >
                  <b-spinner v-if="loadingEsportaDatiCrm" small></b-spinner>
                  <span v-if="loadingEsportaDatiCrm" class="ml-2"
                    >Caricamento in corso...</span
                  >
                  <span v-else>Esporta Dati CRM</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="loadingData">
      <b-col>
        <b-card class="w-100">
          Caricamento in corso...
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-else-if="data.boxes.length > 0"
      align-h="between"
      class="w-100 flex justify-content-between align-items-start flex-gap-2 p-0 m-0 mb-4 mt-4"
    >
      <b-col
        v-for="box in data.boxes"
        :key="box.id"
        align-self="stretch"
        :cols="Math.max(Math.ceil(12 / data.boxes.length), 3)"
      >
        <CardOneValue :title="box.title" :value="box.value" :type="box.type">
        </CardOneValue>
      </b-col>
    </b-row>

    <b-row class="py-4">
      <b-col cols="12">
        <h3>Statistiche</h3>
      </b-col>
    </b-row>

    <b-row class="w-100 p-0 m-0">
      <b-col cols="6" class="py-0 px-1 m-0">
        <b-card class="w-100 px-2" v-if="loadingData">
          Caricamento in corso...
        </b-card>
        <b-card class="w-100 px-2 " v-else-if="data" style="min-height: 619px;">
          <b-row class="m-0 p-0">
            <b-col class="m-0 p-0">
              <h4>Regioni</h4>
            </b-col>
          </b-row>
          <b-row class="m-0 p-0">
            <b-col cols="12" class="m-0 p-0">
              <b-table
                hover
                :items="data.regioniData"
                :fields="regioniFields"
                :current-page="currentPageRegioni"
                :per-page="perPage"
                :filter="filter"
              ></b-table>

              <b-pagination
                v-model="currentPageRegioni"
                :total-rows="data.regioniData.length"
                :per-page="perPage"
                class="flex justify-content-center"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="6" class="py-0 px-1 m-0">
        <b-card class="w-100 px-2" v-if="loadingData">
          Caricamento in corso...
        </b-card>
        <b-card class="w-100 px-2" v-else-if="data" style="min-height: 619px;">
          <b-row class="m-0 p-0">
            <b-col class="m-0 p-0">
              <h4>Agenti</h4>
            </b-col>
          </b-row>
          <b-row class="m-0 p-0">
            <b-col cols="12" class="m-0 p-0">
              <!-- ID 177 è "Catalosi Ezio" che non è in lista -->
              <b-table
                hover
                :items="data.agentiData.filter((x) => x.id !== 177)"
                :fields="agentiFields"
                :current-page="currentPageAgenti"
                :per-page="perPage"
                :filter="filter"
              ></b-table>
              <b-pagination
                v-model="currentPageAgenti"
                :total-rows="data.agentiData.length"
                :per-page="perPage"
                class="flex justify-content-center"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
