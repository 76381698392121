<template>
  <b-card class="w-100 statistica-box" :style="{ minHeight: '100px' }">
    <b-row>
      <b-col cols="12">
        <span class="box-value">{{ formattedValue }}</span>
        <span v-if="type == '€' || type == '$'" class="box-value ml-2">{{
          type
        }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <span class="box-title">{{ title }}</span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "CardOneValue",
  props: {
    title: String,
    value: [String, Number], // Acepta tanto cadenas como números
    type: String,
  },
  computed: {
    formattedValue() {
      // Maneja diferentes formatos de valor y devuelve una cadena formateada
      const parsedValue = parseFloat(this.value);
      return isNaN(parsedValue) ? "N/A" : parsedValue.toFixed(2);
    },
  },
};
</script>

<style scoped>
.statistica-box {
  background-color: var(--whappy-primary);
  color: #ececec;
}
.box-value {
  font-size: 2rem;
  font-weight: bold;
}
.box-title {
  font-size: 1.3rem;
}
</style>
